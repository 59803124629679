import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  id: string;
  full_name: string;
}

const initialState: IUser = {
  id: '',
  full_name: '',
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setReportUser: (state: IUser, { payload }: PayloadAction<IUser>) => {
      state.id = payload.id;
      state.full_name = payload.full_name;
    },
  },
});

export const { setReportUser } = userSlice.actions;
export const userReportReducer = userSlice.reducer;
