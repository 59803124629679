import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

export interface DatePickerState {
  startDate: string;
  endDate: string;
}

const initialState: DatePickerState = {
  startDate: moment().subtract(20, 'days').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

export const datePickerSlice = createSlice({
  name: 'datePickerSlice',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
  },
});

export const { setStartDate, setEndDate } = datePickerSlice.actions;
export const datePickerReducer = datePickerSlice.reducer;
