import React, {Fragment, ReactElement, useEffect, useState} from 'react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import Stack from '@mui/material/Stack';
import {FuelMonthPicker} from './FuelMonthPicker';
import {CardPicker} from './CardPicker';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {useGetFuelsMutation} from '../../services/fuel.service';
import AddFuelEvent from './AddFuelEvent'


type TableProps<TData> = {
    data: TData[];
    columns: ColumnDef<TData>[];
    getRowCanExpand: (row: Row<TData>) => boolean;
};

function Table({data, columns, getRowCanExpand}: TableProps<any>): ReactElement {
    const table = useReactTable<any>({
        data,
        columns,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });


    return (
        <div>
            <table>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => {
                    return (
                        <Fragment key={row.id}>
                            <tr>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    );
                                })}
                            </tr>
                        </Fragment>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

const Fuels = () => {
    const [getFuels, {data, error}] = useGetFuelsMutation();
    const [fuelsData, setFuelsData] = useState<any[]>([]);
    const {month} = useSelector((state: RootState) => state.monthPickerReducer);
    const {cards} = useSelector((state: RootState) => state.contractReducer);
    const [columns, setColumns] = useState<ColumnDef<any>[]>([]);

    useEffect(() => {
        getFuels({
            month: month,
            cards: cards,
        });
    }, [getFuels, month, cards]);

    useEffect(() => {
        if (data && !error) {
            setFuelsData(data[0]['data']);
            const fuels = data[0]['data']
            const newColumns: ColumnDef<any>[] = [
                {
                    id: `date_event`,
                    accessorKey: 'date_event',
                    header: 'Дата',
                },
                {
                    id: `income`,
                    accessorKey: 'income',
                    header: 'Поповнення',
                },
            ];
            if (fuels[0]) {
                fuels[0].fuel_cards.forEach((card: any, index: any) => {
                    newColumns.push({
                        id: `fuel_card_${index}`,
                        header: card.fuel_card_id,
                        columns: [
                            {
                                accessorFn: (row: any) => (row.fuel_cards && row.fuel_cards[index] && row.fuel_cards[index].quantity) ? `${row.fuel_cards[index].quantity}` :  undefined,
                                header: 'Заправлено',
                                id: `fuel_card_${index}_Заправлено`,
                            },
                            {
                                accessorFn: (row: any) => (row.fuel_cards && row.fuel_cards[index] && row.fuel_cards[index].kilometre) ? `${row.fuel_cards[index].kilometre}` :  undefined,
                                header: 'Км',
                                id: `fuel_card_${index}_Км`,
                            },
                            {
                                accessorFn: (row: any) => (row.fuel_cards && row.fuel_cards[index] && row.fuel_cards[index].object_title) ? `${row.fuel_cards[index].object_title}` :  undefined,
                                header: "Об'єкт",
                                id: `fuel_card_${index}_Об'єкт`,
                            },
                            {
                                accessorFn: (row: any) => (row.fuel_cards && row.fuel_cards[index] && row.fuel_cards[index].litre) ? `${row.fuel_cards[index].litre}` :  undefined,
                                header: 'Літри',
                                id: `fuel_card_${index}_Літри`,
                            },
                        ],
                    });
                });
            }
            setColumns(newColumns);
        }
    }, [data, error]);

    return (
        <div className={'Fuels'}>
            <Stack
                direction='row'
                spacing={2}
                sx={{
                    marginBottom: '10px',
                    marginTop: '10px',
                }}
            >
                <FuelMonthPicker/>
                <CardPicker/>
                <AddFuelEvent/>
            </Stack>
            <Table data={fuelsData} columns={columns} getRowCanExpand={() => true}/>
        </div>
    );
};

export default Fuels;
