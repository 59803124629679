import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEditEventMutation } from '../../services/report.service';
import { IEventData } from '../../interfaces/report.interface';

interface Option {
  id: string;
  title: string;
}

export default function EditEvent(row: IEventData) {
  const [open, setOpen] = useState<boolean>(false);
  const [dateEvent, setDateEvent] = useState<moment.Moment>(moment(row.date_event));
  const [timeStartEvent, setTimeStartEvent] = useState<moment.Moment>(
    moment(row.time_start, 'HH:mm'),
  );
  const [timeEndEvent, setTimeEndEvent] = useState<moment.Moment>(moment(row.time_end, 'HH:mm'));
  const [object, setObject] = useState<any>(row.object[0]);
  const [botMode, setBotMode] = useState<any>(row.bot_mode[0]);
  const [note, setNote] = useState<string | null>(row.note);
  const objects = useSelector((state: RootState) => state.objectReducer);
  const reportModes = useSelector((state: RootState) => state.reportModeReducer);
  const [editEvent, { isLoading, isError, error }] = useEditEventMutation();

  const isOptionEqualToValue = (option: Option, value: Option) => {
    return option.id === value.id && option.title === value.title;
  };

  const handleEdit = async (): Promise<void> => {
    try {
      const eventPayload = {
        id: row.id,
        data: {
          date_event: dateEvent.format('YYYY-MM-DD'),
          time_start: timeStartEvent.format('HH:mm'),
          time_end: timeEndEvent.format('HH:mm'),
          object_id: object!.id,
          bot_mode_id: botMode!.id,
          note: note as string,
        },
      };
      await editEvent(eventPayload).unwrap();
      setOpen(false);
      window.location.reload();
    } catch (err) {}
  };

  return (
    <div>
      <Tooltip title='Редагувати'>
        <IconButton color='secondary' onClick={() => setOpen(true)} disabled={open} size='small'>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth='xs'
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Редагування звіту</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled
              label='Початок'
              value={dateEvent}
              minDate={moment().subtract(2, 'month')}
              maxDate={moment()}
              onChange={(newValue: any) => {
                setDateEvent(newValue);
              }}
              slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
            <TimePicker
              label='Час початку'
              format='HH:mm'
              value={timeStartEvent}
              onChange={(newValue: any) => {
                setTimeStartEvent(newValue);
              }}
              slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
            <TimePicker
              label='Час завершення'
              format='HH:mm'
              value={timeEndEvent}
              onChange={(newValue: any) => {
                setTimeEndEvent(newValue);
              }}
              slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
          </LocalizationProvider>
          <Autocomplete
            isOptionEqualToValue={isOptionEqualToValue}
            value={object}
            options={objects}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            getOptionLabel={(object) => object.title}
            onChange={(event: any, newValue: Option | null) => {
              setObject(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} required label="Об'єкт" variant='standard' multiline />
            )}
            sx={{ display: 'block', marginTop: '1rem' }}
          />
          <Autocomplete
            isOptionEqualToValue={isOptionEqualToValue}
            value={botMode}
            options={reportModes}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
            getOptionLabel={(object) => object.title}
            onChange={(event: any, newValue: Option | null) => {
              setBotMode(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} required label='Тип звіту' variant='standard' multiline />
            )}
            sx={{ display: 'block', marginTop: '1rem' }}
          />
          <TextField
            multiline
            fullWidth
            required
            label='Примітка'
            variant='standard'
            value={note || ''}
            onChange={(event) => {
              setNote(event.target.value);
            }}
            sx={{ display: 'block', marginTop: '1rem' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Скасувати
          </Button>
          <Button
            disabled={
              !dateEvent || !timeStartEvent || !timeEndEvent || !object || !botMode || !note
            }
            onClick={handleEdit}
          >
            Надіслати
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
