import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IContract {
  cards: any;
}

const initialState: IContract = {
  cards: [],
};

export const contractSlice = createSlice({
  name: 'contractSlice',
  initialState,
  reducers: {
    setContract: (state: IContract, { payload }: PayloadAction<any>) => {
      state.cards = payload.cards;
    },
  },
});

export const { setContract } = contractSlice.actions;
export const contractReducer = contractSlice.reducer;
