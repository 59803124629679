import * as React from 'react';
import Paper from '@mui/material/Paper';
import { GroupingState, IntegratedGrouping, ViewState } from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DayView,
  GroupingPanel,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect, useState } from 'react';
import { useGetSchedulerMutation } from '../../services/scheduler.service';

export interface IScheduler {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  location: string;
}

const resources = [
  {
    fieldName: 'location',
    title: 'Location',
    instances: [
      { id: '000010316', text: 'Офіс' },
      { id: '000013792', text: 'За межами офісу' },
      { id: '000010295', text: 'Відрядження' },
      { id: '000014491', text: 'Понаднормові' },
      { id: '210', text: 'СКД Офіс' },
      { id: '208', text: 'СКД Кухня' },
    ],
  },
];

const SchedulerTable = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [getScheduler, { data, error }] = useGetSchedulerMutation();
  const [scheduler, setScheduler] = useState<IScheduler[]>([]);
  const [startDayHour, setStartDayHour] = useState<number>(8);
  const [endDayHour, setEndDayHour] = useState<number>(18);

  const currentDateChange = (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  useEffect(() => {
    getScheduler({ date_event: currentDate.toISOString().split('T')[0] });
  }, [getScheduler, currentDate]);

  useEffect(() => {
    if (data && !error) {
      setScheduler(data);
      if (data.length > 0) {
        const startHour = data.reduce((minHour: any, event: any) => {
          const startDate = new Date(event.startDate);
          return startDate.getHours() < minHour ? startDate.getHours() : minHour;
        }, 24);
        const endHour = data.reduce((maxHour: any, event: any) => {
          const endDate = new Date(event.endDate);
          return endDate && endDate.getHours() > maxHour ? endDate.getHours() : maxHour;
        }, 0);

        const adjustedStartHour = startHour > 8 ? 8 : startHour;
        const adjustedEndHour = endHour < 18 ? 18 : endHour;
        setStartDayHour(adjustedStartHour);
        setEndDayHour(adjustedEndHour);
      }
    } else if (error) {
    }
  }, [data, error]);

  return (
    <Paper>
      <Scheduler data={scheduler} locale='uk-UA'>
        <ViewState currentDate={currentDate} onCurrentDateChange={currentDateChange} />
        <DayView startDayHour={startDayHour} endDayHour={endDayHour} />
        <GroupingState
          grouping={[
            {
              resourceName: 'location',
            },
          ]}
        />
        <Appointments />
        <AppointmentTooltip />
        <Resources data={resources} />
        <IntegratedGrouping />
        <Toolbar />
        <GroupingPanel />
        <DateNavigator />
        <TodayButton />
      </Scheduler>
    </Paper>
  );
};

export default SchedulerTable;
