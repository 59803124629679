import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const schedulerAPI = createApi({
  reducerPath: 'schedulerAPI',
  tagTypes: ['Scheduler'],
  baseQuery,
  endpoints: (build) => ({
    getScheduler: build.mutation<any, { date_event: string }>({
      query: ({ date_event }) => ({
        url: `/scheduler/?date_event=${date_event}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSchedulerMutation } = schedulerAPI;
