import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from './baseQuery';

export interface ILoginRequest {
  phone_number: string;
  password: string;
}

export const authAPI = createApi({
  reducerPath: 'authAPI',
  tagTypes: ['Auth'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/auth/`,
  }),
  endpoints: (build) => ({
    login: build.mutation<any, ILoginRequest>({
      query: ({ phone_number, password }) => ({
        url: `/login/`,
        method: 'POST',
        body: { phone_number, password },
      }),
    }),
    sendPassword: build.mutation<any, string>({
      query: (phone_number) => ({
        url: `/send-password/`,
        method: 'POST',
        body: { phone_number },
      }),
    }),
  }),
});

export const { useLoginMutation, useSendPasswordMutation } = authAPI;
