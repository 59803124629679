import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from './baseQuery';
import moment from "moment/moment";

export const fuelAPI = createApi({
    reducerPath: 'fuelsAPI',
    tagTypes: ['Fuel'],
    baseQuery,
    endpoints: (build) => ({
        getFuels: build.mutation<
            any,
            {
                cards?: string
                month?: string;
            }
        >({
            query: ({cards, month}) => {
                const urlParams = new URLSearchParams();
                if (cards && month) {
                    const start = moment(month).startOf('month').format("YYYY-MM-DD")
                    const end = moment(month).endOf('month').format("YYYY-MM-DD")
                    urlParams.set('start', start);
                    urlParams.set('end', end);
                    urlParams.set('cards', cards);
                }

                const url = `/fuels/objects/?${urlParams.toString()}`;

                return {
                    url,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetFuelsMutation,
} = fuelAPI;
