import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IObject {
  id: string;
  title: string;
  ancestor: string;
  is_group: number;
  is_active: number;
  exp_date: string;
}

const initialState: IObject[] = [];

export const objectSlice = createSlice({
  name: 'objectSlice',
  initialState,
  reducers: {
    setObject: (state: IObject[], { payload }: PayloadAction<IObject[]>) => {
      return payload;
    },
  },
});

export const { setObject } = objectSlice.actions;
export const objectReducer = objectSlice.reducer;
