import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setReportFilter } from '../../redux/slices/reportFilter.slice';

export function ReportFilter() {
  const dispatch = useDispatch();
  const reportFilter = useSelector((state: RootState) => state.reportFilterReducer);
  const [selectedValue, setSelectedValue] = useState(reportFilter.id);

  useEffect(() => {
    setSelectedValue(reportFilter.id);
  }, [reportFilter]);

  const handleReportFilterChange = useCallback(
    (event: SelectChangeEvent<string>, child: any) => {
      let id = event.target.value;
      if (id) {
        dispatch(setReportFilter({ id: id }));
        setSelectedValue(id);
      }
    },
    [dispatch],
  );

  return (
    <FormControl sx={{ m: 1, maxWidth: 150 }} fullWidth>
      <InputLabel>Фільтр</InputLabel>
      <Select
        sx={{ maxWidth: 150 }}
        value={selectedValue}
        label='Фільтр'
        onChange={handleReportFilterChange}
      >
        <MenuItem value={'is_updated'}>Не оновлені в 1С</MenuItem>
        <MenuItem value={'is_accepted'}>Не прийняті в 1С</MenuItem>
        <MenuItem value={'is_deleted'}>Некоректні</MenuItem>
      </Select>
    </FormControl>
  );
}
