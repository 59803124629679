import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  tagTypes: ['User'],
  baseQuery,
  endpoints: (build) => ({
    getUsers: build.mutation<any, null>({
      query: () => ({
        url: `/users/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUsersMutation } = userAPI;
