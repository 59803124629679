import React from 'react';
import {AppBar, Button, Container, Toolbar} from '@mui/material';
import {useAppDispatch} from '../../redux/hooks';
import {resetState} from '../../redux/slices/auth.slice';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

const NavBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {is_staff} = useSelector((state: RootState) => state.authReducer);

    const logout = () => {
        dispatch(resetState());
        navigate('');
    };
    return (
        <AppBar position='static'>
            <Container>
                <Toolbar disableGutters>
                    <Button color='inherit' href='/report'>
                        Звіти
                    </Button>
                    <Button color='inherit' href='/scheduler'>
                        Графік
                    </Button>
                    {is_staff ? <Button color='inherit' href='/fuel'>
                        Паливо
                    </Button> : null}
                    <Button color='inherit' onClick={logout}>
                        Вихід
                    </Button>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;
