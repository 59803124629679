import React, {useCallback} from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment, {Moment} from 'moment';
import 'moment/locale/uk';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {setMonth} from '../../redux/slices/monthpicker.slice';
import {setContract} from "../../redux/slices/contract.slice";

export function FuelMonthPicker() {
    const dispatch = useDispatch();
    const month = useSelector((state: RootState) => state.monthPickerReducer.month);

    const handleMonthChange = useCallback(
        (date: Moment | null) => {
            if (date) {
                dispatch(setMonth(date.format('YYYY-MM')));
                dispatch(setContract({cards: []}));
            }
        },
        [dispatch],
    );

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    sx={{maxWidth: 170}}
                    label='Місяць'
                    views={['month', 'year']}
                    value={moment(month)}
                    onChange={handleMonthChange}
                    slotProps={{textField: {variant: 'outlined'}}}
                />
            </LocalizationProvider>
        </>
    );
}
