import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import Avatar from '@mui/material/Avatar';
import { setAuthenticatedUser } from '../../redux/slices/auth.slice';
import { useLoginMutation, useSendPasswordMutation } from '../../services/auth.service';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

const defaultTheme = createTheme();

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [login, { data: dataLogin, error: errorLogin }] = useLoginMutation();
  const [sendPassword, { isSuccess: sendPasswordIsSuccess, isError: sendPasswordIsError }] =
    useSendPasswordMutation();

  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      navigate('report');
    }
    if (dataLogin && !errorLogin) {
      sessionStorage.setItem('user', JSON.stringify(dataLogin));
      dispatch(setAuthenticatedUser(dataLogin));
      navigate('report');
    } else if (errorLogin) {
      enqueueSnackbar('Невірний пароль', { variant: 'error' });
    }
  }, [dataLogin, errorLogin]);

  useEffect(() => {
    if (sendPasswordIsSuccess && !sendPasswordIsError) {
      enqueueSnackbar('Пароль надіслано в телеграм бот', { variant: 'success' });
    } else if (sendPasswordIsError) {
      enqueueSnackbar('Помилка при надсилані паролю', { variant: 'error' });
    }
  }, [sendPasswordIsSuccess, sendPasswordIsError]);

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      phone_number: formData.get('phone_number') as string,
      password: formData.get('password') as string,
    };
    login(credentials);
  };

  const handlePhoneNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 12) {
      sendPassword(event.target.value);
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Box component='form' onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='phone_number'
              label='Номер телефону'
              name='phone_number'
              onChange={handlePhoneNumber}
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Пароль'
              type='password'
              id='password'
            />
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              Увійти
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
