import React, { useState } from 'react';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteEventMutation } from '../../services/report.service';
import { IEventData } from '../../interfaces/report.interface';

export default function DeleteEvent(row: IEventData) {
  const [open, setOpen] = useState<boolean>(false);
  const [eventId, setEventId] = useState<number>(row.id);
  const [deleteEvent, { isLoading, isError, error }] = useDeleteEventMutation();

  const handleDelete = async () => {
    try {
      await deleteEvent(row.id).unwrap();
      setOpen(false);
    } catch (err) {}
  };
  return (
    <div>
      <Tooltip title='Видалити'>
        <IconButton
          color='secondary'
          onClick={() => {
            setOpen(true);
          }}
          disabled={open}
          size='small'
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{`Видалити звіт №${eventId}?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Скасувати
          </Button>
          <Button onClick={handleDelete}>Видалити</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
