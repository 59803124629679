import React, { useState, ReactElement } from 'react';
import moment, { Moment } from 'moment';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useCreateEventMutation } from '../../services/report.service';

interface Object {
  id: string;
  title: string;
}

interface BotMode {
  id: string;
  title: string;
}

export default function AddFuelEvent(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const [dateEvent, setDateEvent] = useState<Moment>(moment());
  const [timeStartEvent, setTimeStartEvent] = useState<Moment>(moment());
  const [timeEndEvent, setTimeEndEvent] = useState<Moment>(moment());
  const [selectedObject, setSelectedObject] = useState<Object | null>(null);
  const [selectedBotMode, setSelectedBotMode] = useState<BotMode | null>(null);
  const [note, setNote] = useState<string>('');
  const objects = useSelector((state: RootState) => state.objectReducer);
  const reportModes = useSelector((state: RootState) => state.reportModeReducer);
  const [createEvent, { isLoading, isError, error }] = useCreateEventMutation();

  const handleSubmit = async (): Promise<void> => {
    try {
      const eventPayload = {
        date_event: dateEvent.format('YYYY-MM-DD'),
        time_start: timeStartEvent.format('HH:mm'),
        time_end: timeEndEvent.format('HH:mm'),
        object_id: selectedObject!.id,
        bot_mode_id: selectedBotMode!.id,
        note: note as string,
      };
      await createEvent(eventPayload).unwrap();
      setOpen(false);
      window.location.reload();
    } catch (err) {}
  };

  return (
    <>
      <div>
        <Fab
          color='secondary'
          aria-label='add'
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        <Dialog
          maxWidth='xs'
          fullWidth
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle>Новий звіт</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label='Початок'
                value={dateEvent}
                minDate={moment().subtract(2, 'month')}
                maxDate={moment()}
                onChange={(newValue: any) => {
                  setDateEvent(newValue);
                }}
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                sx={{ display: 'block', marginTop: '1rem' }}
              />
              <TimePicker
                label='Час початку'
                format='HH:mm'
                value={timeStartEvent}
                onChange={(newValue: any) => {
                  setTimeStartEvent(newValue);
                }}
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                sx={{ display: 'block', marginTop: '1rem' }}
              />
              <TimePicker
                label='Час завершення'
                format='HH:mm'
                value={timeEndEvent}
                onChange={(newValue: any) => {
                  setTimeEndEvent(newValue);
                }}
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                sx={{ display: 'block', marginTop: '1rem' }}
              />
            </LocalizationProvider>
            <Autocomplete
              options={objects}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.title}
                </li>
              )}
              getOptionLabel={(object) => object.title}
              onChange={(event: any, newValue: any | null) => {
                setSelectedObject(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required label="Об'єкт" variant='standard' multiline />
              )}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
            <Autocomplete
              options={reportModes}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.title}
                </li>
              )}
              getOptionLabel={(object) => object.title}
              onChange={(event: any, newValue: any | null) => {
                setSelectedBotMode(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} required label='Тип звіту' variant='standard' multiline />
              )}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
            <TextField
              multiline
              fullWidth
              required
              label='Примітка'
              variant='standard'
              value={note}
              onChange={(newValue) => {
                setNote(newValue.target.value);
              }}
              sx={{ display: 'block', marginTop: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Скасувати
            </Button>
            <Button
              disabled={
                !(
                  dateEvent &&
                  timeStartEvent &&
                  timeEndEvent &&
                  selectedObject &&
                  selectedBotMode &&
                  note
                )
              }
              onClick={handleSubmit}
            >
              Надіслати
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
