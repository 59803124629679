import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  id: string;
  full_name: string;
  is_staff: number;
  token: string;
}

const initialState: AuthState = {
  id: '',
  full_name: '',
  is_staff: 0,
  token: '',
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAuthenticatedUser: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
      state.id = payload.id;
      state.full_name = payload.full_name;
      state.is_staff = payload.is_staff;
      state.token = payload.token;
    },
    resetState: (state: AuthState) => {
      state.id = '';
      state.full_name = '';
      state.is_staff = 0;
      state.token = '';
      sessionStorage.setItem('user', '');
    },
  },
});

export const { setAuthenticatedUser, resetState } = authSlice.actions;
export const authReducer = authSlice.reducer;
