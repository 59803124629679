import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const objectAPI = createApi({
  reducerPath: 'objectAPI',
  tagTypes: ['Object'],
  baseQuery,
  endpoints: (build) => ({
    getObject: build.mutation<any, null>({
      query: () => ({
        url: `/objects/?is_group=0`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetObjectMutation } = objectAPI;
