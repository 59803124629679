import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useAppDispatch, useAppSelector } from './app/redux/hooks';
import { setAuthenticatedUser } from './app/redux/slices/auth.slice';
import { RootState, store } from './app/redux/store';
import { BrowserRouter, Navigate, Outlet, Route, RouteProps, Routes } from 'react-router-dom';
import NavBar from './app/pages/navbar/NavBar';
import LoginPage from './app/pages/login/LoginPage';
import Reports from './app/pages/reports/Reports';
import SchedulerTable from './app/pages/scheduler/Scheduler';
import Fuels from './app/pages/fuels/Fuels';

const PrivateRoute = ({ ...rest }: RouteProps): React.ReactElement | null => {
  const { token } = useAppSelector((state: RootState) => state.authReducer);
  return token ? <Outlet /> : <Navigate to='/' />;
};

const App = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state: RootState) => state.authReducer);
  const [finishedInitialize, setFinishedInitialize] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem('user');
    if (token) {
      dispatch(setAuthenticatedUser(JSON.parse(token)));
    }
    setFinishedInitialize(true);
  }, [dispatch]);

  return (
    <div>
      {finishedInitialize ? (
        <Provider store={store}>
          <BrowserRouter>
            {token ? <NavBar /> : null}
            <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/report' element={<PrivateRoute />}>
                <Route path='/report' element={<Reports />} />
              </Route>
              <Route path='/scheduler' element={<PrivateRoute />}>
                <Route path='/scheduler' element={<SchedulerTable />} />
              </Route>
              <Route path='/fuel' element={<PrivateRoute />}>
                <Route path='/fuel' element={<Fuels />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      ) : (
        <></>
      )}
    </div>
  );
};

export default App;
