import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../redux/store';

export const API_BASE_URL = 'https://report-work.inteldim.support/api';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).authReducer;
    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }
    return headers;
  },
});
