import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {useGetContractsMutation} from '../../services/contract.service';
import {setContract} from '../../redux/slices/contract.slice';
import {SelectChangeEvent} from '@mui/material';
import {setReportUser} from "../../redux/slices/user.slice";


export interface IContract {
    contract_title: string;
    cards: [];
}

export interface ICard {
    card_id: string;
    card_title: string;
}

export function CardPicker() {
    const dispatch = useDispatch();
    const [contracts, setContracts] = useState<IContract[]>([]);
    const contract = useSelector((state: RootState) => state.contractReducer.cards);
    const [card, setCards] = useState<string[]>([]);
    const {month} = useSelector((state: RootState) => state.monthPickerReducer);
    const [getContracts, {data, error}] = useGetContractsMutation();

    useEffect(() => {
        getContracts({month});
    }, [getContracts, month]);

    useEffect(() => {
        if (data && !error) {
            setContracts(data);
        } else if (error) {
        }
    }, [data, error]);


    const handleChange = useCallback(
        (event: SelectChangeEvent<typeof card>) => {
            const {
                target: {value},
            } = event;
            dispatch(setContract({cards:
                typeof value === 'string' ? value.split(',') : value}
            ));
        },
        [dispatch],
    );

    return (
        <FormControl sx={{m: 1, maxWidth: 250}} fullWidth>
            <InputLabel>Паливні картки</InputLabel>
            <Select
                sx={{maxWidth: 250}}
                multiple
                value={contract}
                label='Паливні картки'
                onChange={handleChange}
            >
                {contracts.map((group) => (
                    group.cards.map((card: ICard) => (
                        <MenuItem key={card.card_id} value={card.card_id}>
                            {card.card_id} - {card.card_title}
                        </MenuItem>
                    ))
                ))}
            </Select>
        </FormControl>
    );
}
