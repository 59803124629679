import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authAPI } from '../services/auth.service';
import { userAPI } from '../services/user.service';
import { reportAPI } from '../services/report.service';
import { objectAPI } from '../services/object.service';
import { schedulerAPI } from '../services/scheduler.service';
import { reportModeAPI } from '../services/reportMode.service';
import { contractAPI } from '../services/contract.service';
import { fuelAPI } from '../services/fuel.service';
import { authReducer } from './slices/auth.slice';
import { datePickerReducer } from './slices/datepicker.slice';
import { objectReducer } from './slices/object.slice';
import { userReportReducer } from './slices/user.slice';
import { reportModeReducer } from './slices/reportMode.slice';
import { reportFilterReducer } from './slices/reportFilter.slice';
import { monthPickerReducer } from './slices/monthpicker.slice';
import { contractReducer } from './slices/contract.slice';


const rootReducer = combineReducers({
  authReducer,
  datePickerReducer,
  userReportReducer,
  objectReducer,
  reportModeReducer,
  reportFilterReducer,
  monthPickerReducer,
  contractReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [reportAPI.reducerPath]: reportAPI.reducer,
  [objectAPI.reducerPath]: objectAPI.reducer,
  [schedulerAPI.reducerPath]: schedulerAPI.reducer,
  [reportModeAPI.reducerPath]: reportModeAPI.reducer,
  [contractAPI.reducerPath]: contractAPI.reducer,
  [fuelAPI.reducerPath]: fuelAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authAPI.middleware,
      reportAPI.middleware,
      userAPI.middleware,
      objectAPI.middleware,
      schedulerAPI.middleware,
      reportModeAPI.middleware,
      contractAPI.middleware,
      fuelAPI.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
