import { useGetReportsMutation } from '../../services/report.service';
import { useGetObjectMutation } from '../../services/object.service';
import { useGetReportModeMutation } from '../../services/reportMode.service';
import { IEventData, IReportData } from '../../interfaces/report.interface';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReportDatePicker } from './ReportDatePicker';
import { UserPicker } from './UserPicker';
import Stack from '@mui/material/Stack';
import EditEvent from './EditEvent';
import AddEvent from './AddEvent';
import DeleteEvent from './DeleteEvent';
import { ReportFilter } from './ReportFilter';
import { setObject } from '../../redux/slices/object.slice';
import { setReportMode } from '../../redux/slices/reportMode.slice';

const columns: ColumnDef<IReportData>[] = [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => (
      <span
        {...{
          onClick: row.getToggleExpandedHandler(),
          style: { cursor: 'pointer' },
        }}
      >
        {row.getIsExpanded() ? (
          <ExpandLessIcon fontSize='small' />
        ) : (
          <ExpandMoreIcon fontSize='small' />
        )}
      </span>
    ),
  },
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorFn: (row) => `${row.user[0].full_name}`,
    header: 'ПІБ',
  },
  {
    accessorKey: 'date_report',
    header: 'Дата звіту',
  },
  {
    accessorKey: 'datetime_created',
    header: 'Створено',
  },
  {
    accessorKey: 'datetime_modified_bot',
    header: 'Редаговано в боті',
  },
  {
    accessorKey: 'datetime_modified_1c',
    header: 'Редаговано в 1С',
  },
  {
    accessorKey: 'datetime_sent',
    header: 'Надіслано в 1С',
  },
];

const columnsEvent: ColumnDef<IEventData>[] = [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => (
      <span>
        {EditEvent(row.original)}
        {DeleteEvent(row.original)}
      </span>
    ),
  },
  {
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorKey: 'date_event',
    header: 'Дата події',
  },
  {
    accessorKey: 'time_start',
    header: 'Початок',
  },
  {
    accessorKey: 'time_end',
    header: 'Завершення',
  },
  {
    accessorKey: 'time_total',
    header: 'Загалом',
  },
  {
    accessorFn: (row) => `${row.object[0].title}`,
    header: "Об'єкт",
  },
  {
    accessorFn: (row) => `${row.bot_mode[0].title}`,
    header: 'Тип події',
  },
  {
    accessorKey: 'note',
    header: 'Примітка',
  },
];

type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  getRowCanExpand: (row: Row<TData>) => boolean;
};

type SubTableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
};

function Table({ data, columns, getRowCanExpand }: TableProps<IReportData>): ReactElement {
  const [sorting, setSorting] = React.useState<SortingState>([{desc: true, id: 'date_report'}])
  const table = useReactTable<IReportData>({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderSubTable = ({ row }: { row: Row<IReportData> }) => {
    return <SubTable data={row.original.events} columns={columnsEvent} />;
  };

  return (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                          {...{
                            className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}>{flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            let color: string;
            if (!row.original.is_completed) {
              color = '#d9b2b2';
            } else if (row.original.is_completed && !row.original.is_updated) {
              color = '#f0efc7';
            } else if (row.original.is_accepted) {
              color = '#c1f5c4';
            }
            return (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} style={{ backgroundColor: color }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length}>{renderSubTable({ row })}</td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function SubTable({ data, columns }: SubTableProps<IEventData>): ReactElement {
  const [sorting, setSorting] = React.useState<SortingState>([{desc: false, id: 'time_start'}])
  const table = useReactTable<IEventData>({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div

                          {...{
                            className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}>{flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const Reports = () => {
  const dispatch = useDispatch();
  const [getReports, { data: dataReports, error: errorReports }] = useGetReportsMutation();
  const [getObjects, { data: dataObjects, error: errorObjects }] = useGetObjectMutation();
  const [getReportModes, { data: dataReportModes, error: errorReportModes }] =
    useGetReportModeMutation();
  const [reports, setReports] = useState<IReportData[]>([]);
  const { startDate, endDate } = useSelector((state: RootState) => state.datePickerReducer);
  const { id: user_id } = useSelector((state: RootState) => state.userReportReducer);
  const { id: reportFilter } = useSelector((state: RootState) => state.reportFilterReducer);
  const { is_staff } = useSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    getReports({
      startDate: startDate,
      endDate: endDate,
      user_id: user_id,
      report_filter: reportFilter,
    });
    getObjects(null);
    getReportModes(null);
  }, [getReports, startDate, endDate, user_id, reportFilter, getObjects, getReportModes]);

  useEffect(() => {
    if (dataReports && !errorReports) {
      setReports(dataReports);
    } else if (errorReports) {
    }
    if (dataObjects && !errorObjects) {
      dispatch(setObject(dataObjects));
    } else if (errorObjects) {
    }
    if (dataReportModes && !errorReportModes) {
      dispatch(setReportMode(dataReportModes));
    } else if (errorReportModes) {
    }
  }, [dataReports, errorReports, dataObjects, errorObjects, dataReportModes, errorReportModes]);

  return (
    <div className={'Reports'}>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          marginBottom: '10px',
          marginTop: '10px',
        }}
      >
        <ReportDatePicker />
        {is_staff ? <UserPicker /> : null}
        {is_staff ? <ReportFilter /> : null}
        <AddEvent />
      </Stack>
      <Table data={reports} columns={columns} getRowCanExpand={() => true} />
    </div>
  );
};

export default Reports;
