import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const reportModeAPI = createApi({
  reducerPath: 'reportModeAPIAPI',
  tagTypes: ['ReportMode'],
  baseQuery,
  endpoints: (build) => ({
    getReportMode: build.mutation<any, null>({
      query: () => ({
        url: `/reports-mode/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetReportModeMutation } = reportModeAPI;
