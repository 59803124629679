import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

export interface MonthPickerState {
  month: string;
}

const initialState: MonthPickerState = {
  month: moment().format('YYYY-MM'),
};

export const monthPickerSlice = createSlice({
  name: 'monthPickerSlice',
  initialState,
  reducers: {
    setMonth: (state, action) => {
      state.month = action.payload;
    },
  },
});

export const { setMonth} = monthPickerSlice.actions;
export const monthPickerReducer = monthPickerSlice.reducer;
