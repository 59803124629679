import React, { useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import 'moment/locale/uk';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setEndDate, setStartDate } from '../../redux/slices/datepicker.slice';

export function ReportDatePicker() {
  const dispatch = useDispatch();
  const startDate = useSelector((state: RootState) => state.datePickerReducer.startDate);
  const endDate = useSelector((state: RootState) => state.datePickerReducer.endDate);

  const handleStartDateChange = useCallback(
    (date: Moment | null) => {
      if (date) {
        dispatch(setStartDate(date.format('YYYY-MM-DD')));
      }
    },
    [dispatch],
  );

  const handleEndDateChange = useCallback(
    (date: Moment | null) => {
      if (date) {
        dispatch(setEndDate(date.format('YYYY-MM-DD')));
      }
    },
    [dispatch],
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          sx={{maxWidth:150}}
          label='Початок'
          value={moment(startDate)}
          onChange={handleStartDateChange}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
        <DatePicker
          sx={{maxWidth:150}}
          label='Завершення'
          value={moment(endDate)}
          onChange={handleEndDateChange}
          slotProps={{ textField: { variant: 'outlined' } }}
        />
      </LocalizationProvider>
    </>
  );
}
