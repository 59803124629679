import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReportFilter {
  id: string;
}

const initialState: IReportFilter = {
  id: '',
};

export const reportFilterSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setReportFilter: (state: IReportFilter, { payload }: PayloadAction<IReportFilter>) => {
      state.id = payload.id;
    },
  },
});

export const { setReportFilter } = reportFilterSlice.actions;
export const reportFilterReducer = reportFilterSlice.reducer;
