import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from './baseQuery';
import moment from "moment";

export const contractAPI = createApi({
    reducerPath: 'contractAPI',
    tagTypes: ['Contract'],
    baseQuery,
    endpoints: (build) => ({
        getContracts: build.mutation<any, { month: string }>({
            query: ({month}) => {
                const urlParams = new URLSearchParams();

                if (month) {
                    const start = moment(month).startOf('month').format("YYYY-MM-DD")
                    const end = moment(month).endOf('month').format("YYYY-MM-DD")
                    urlParams.set('start', start);
                    urlParams.set('end', end);
                }

                const url = `/fuels/contracts/?${urlParams.toString()}`;
                return {
                    url,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {useGetContractsMutation} = contractAPI;
