import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const reportAPI = createApi({
  reducerPath: 'reportsAPI',
  tagTypes: ['Report'],
  baseQuery,
  endpoints: (build) => ({
    getReports: build.mutation<
      any,
      {
        startDate: string;
        endDate: string;
        user_id?: string;
        report_filter?: string;
      }
    >({
      query: ({ startDate, endDate, user_id, report_filter }) => {
        const urlParams = new URLSearchParams();
        if (user_id) {
          urlParams.set('user_id', user_id);
        }
        if (report_filter) {
          if (report_filter === 'is_deleted') {
            urlParams.set(report_filter, '1');
          } else {
            urlParams.set(report_filter, '0');
          }
        } else {
          urlParams.set('date_report__gte', startDate);
          urlParams.set('date_report__lte', endDate);
        }

        const url = `/reports2/?${urlParams.toString()}`;

        return {
          url,
          method: 'GET',
        };
      },
    }),
    editEvent: build.mutation<any, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `/events/${id}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    createEvent: build.mutation<any, any>({
      query: (data) => ({
        url: `/events/`,
        method: 'POST',
        body: data,
      }),
    }),
    deleteEvent: build.mutation<any, number>({
      query: (id) => ({
        url: `/events/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetReportsMutation,
  useEditEventMutation,
  useCreateEventMutation,
  useDeleteEventMutation,
} = reportAPI;
