import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useGetUsersMutation } from '../../services/user.service';
import { setReportUser } from '../../redux/slices/user.slice';
import { SelectChangeEvent } from '@mui/material';

export interface IUser {
  id: string;
  full_name: string;
}

export function UserPicker() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState<IUser[]>([]);
  const user = useSelector((state: RootState) => state.userReportReducer);
  const [getUsers, { data, error }] = useGetUsersMutation();

  useEffect(() => {
    getUsers(null);
  }, [getUsers]);

  useEffect(() => {
    if (data && !error) {
      setUsers(data);
    } else if (error) {
    }
  }, [data, error]);

  const handleUserChange = useCallback(
    (event: SelectChangeEvent<string>, child: any) => {
      let fullName = event.target.value;
      let id = child.key.substring(2);
      if (fullName && id) {
        dispatch(setReportUser({ id: id, full_name: fullName }));
      }
    },
    [dispatch],
  );

  return (
    <FormControl sx={{ m: 1, maxWidth: 150 }} fullWidth>
      <InputLabel>Працівник</InputLabel>
      <Select
        sx={{ maxWidth: 150 }}
        value={user.full_name}
        label='Працівник'
        onChange={handleUserChange}
      >
        {users.map((user) => (
          <MenuItem key={user.id} value={user.full_name}>
            {user.full_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
