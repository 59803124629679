import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReportMode {
  id: string;
  title: string;
  is_active: number;
}

const initialState: IReportMode[] = [];

export const reportModeSlice = createSlice({
  name: 'reportModeSlice',
  initialState,
  reducers: {
    setReportMode: (state: IReportMode[], { payload }: PayloadAction<IReportMode[]>) => {
      return payload;
    },
  },
});

export const { setReportMode } = reportModeSlice.actions;
export const reportModeReducer = reportModeSlice.reducer;
